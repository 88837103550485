.loading:after {
    overflow: hidden;
    position: absolute;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
    animation: ellipsis steps(4,end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
}
  
  @keyframes ellipsis {
    to {
      width: 20px;    
    }
  }
  
  @-webkit-keyframes ellipsis {
    to {
      width: 20px;    
    }
  }