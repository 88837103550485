.container {
    height: 100%;
    min-height: calc(100vh - 310px);
    position: relative;
    .box {
        width: 100%;
        height: 400px;
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }
    .loaderWrapper {
        width: 100%;
        height: 300px;
        padding: 20px;
    }

    .tableSettings {
        justify-content: flex-end;
        display: flex;
        padding: 20px 0;

        .alignLeft,
        .alignRight,
        .alignCenter {
            button {
                padding: 0;
            }
            display: inline-block;
            align-self: center;
            > div {
                display: inline-block;
            }
        }

    }
    .textAlign {
        font-family: Arial, Helvetica, sans-serif;
    }
}